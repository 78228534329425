var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pt-8",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "align-self": "center"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "128",
      "color": "error"
    }
  }, [_c('v-icon', {
    staticClass: "pa-8",
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiAlert) + " ")])], 1), _c('div', {
    staticClass: "text-h3 my-8"
  }, [_vm._v("This page does not exist!")]), _c('v-btn', {
    staticClass: "pa-5 py-5 mt-8",
    attrs: {
      "x-large": "",
      "to": "/",
      "color": "accent"
    }
  }, [_vm._v(" Go Home ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }